import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { Row, Col } from 'antd';
import Card from '../../components/simple/Card';
import CardTitle from './CardTitle';
import CardTable from './CardTable';
import DynamicFilters from '../../components/custom/DynamicFilters';
import './DashboardPage.scss';

function Dashboard(props) {
  const { dashboard, setRange, tableProps, getAbsences, getDelays, getCancellations } = props;
  const { loading, range, page, orderBy } = dashboard;
  const [filters, setFilters] = useState({});

  const updateRange = (name) => (value) => setRange(name, value);

  const fetchData = (name, fetchFunction) => {
    if (range[name]) {
      fetchFunction(range[name], filters, page, orderBy);
    }
  };

  const cancellationsMapper = (data) =>
    (data || []).map((cancellation) => ({
      ...cancellation,
      replacements: (cancellation.replacements || []).map((replacement) => replacement.date),
    }));

  useEffect(() => {
    fetchData('absences', getAbsences);
  }, [range['absences']]);

  useEffect(() => {
    fetchData('delays', getDelays);
  }, [range['delays']]);

  useEffect(() => {
    fetchData('cancellations', getCancellations);
  }, [range['cancellations'], filters, page, orderBy.column, orderBy.order]);


  return (
    <div className='Dashboard'>
      <Row className='top-row'>
        <Col className='inside' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card title={<CardTitle onChange={ updateRange('absences') } title='Ausencias'/>}>
            <CardTable name='absences' loading={ loading.ABSENCES && loading.ABSENCES.state }/>
          </Card>
        </Col>
        <Col className='inside' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card title={<CardTitle onChange={ updateRange('delays') } title='Retardos'/>}>
            <CardTable name='delays' loading={ loading.DELAYS && loading.DELAYS.state }/>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='inside' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card title={<CardTitle advanced={true} onChange={updateRange('cancellations')} title='Cancelaciones' />}>
            <DynamicFilters
              {...props}
              onChange={filters => {
                tableProps(1).then(() => setFilters(filters));
              }}
              data={dashboard.cancellations}
            />
            <CardTable
              mapper={cancellationsMapper}
              onChange={(pager, filter, sort) => tableProps(pager.current, sort)}
              name='cancellations'
              loading={loading.CANCELLATIONS && loading.CANCELLATIONS.state}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect((state) => ({
  dashboard: state.get('dashboard')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(Dashboard);