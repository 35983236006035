import { Checkbox, DatePicker, Form } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import CellDatePicker from "../../simple/CellDatePicker";
import FormWrapper from "../FormWrapper";
import TeacherSelect from "../TeacherSelect";

import './EditTeacherAssignationForm.scss';

const EditTeacherAssignationForm = (props) => {
  const {
    form,
    values,
    setValues,
    classReducer,
    groupsReducer,
    onClose,
    groupId,
    group,
    assignMSAClassTeacher,
  } = props;

  const { classesOrigin } = classReducer;

  const [multipleClasses, setMultipleClasses] = useState(false);
  const [mainTeacher, setMainTeacher] = useState(false);

  const onChange = (formValue) => {
    setValues({
      ...values,
      ...formValue,
    });
  };

  const handleMultipleClassesCheckbox = (e) => {
    form.validateFields([e.target.value], { force: true });
    setMultipleClasses(e.target.checked);
  }

  const handleMainGroupTeacherCheckbox = (e) => {
    form.validateFields([e.target.value], { force: true });
    setMainTeacher(e.target.checked);
  }

  const onFinish = () => {
    let endDate = moment(multipleClasses ? values.end_date : values.start_date).format('YYYY-MM-DD');
    if (mainTeacher) {
      endDate = null;
    }
    const payload = {
      ...values,
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      end_date: endDate
    };

    assignMSAClassTeacher(groupId, payload);
  };

  useEffect(() => {
    if (groupsReducer.loading.ASSIGN_MSACLASS_TEACHER?.state === 'success') {
      window.location.reload();
    }
  }, [groupsReducer.loading.ASSIGN_MSACLASS_TEACHER]);
  
  return (
    <div className="edit-teacher-modal">
      <h1>Editar asignación de profesor</h1>

      <div>
        <FormWrapper
          {...props}
          name="teacher-assignation"
          form={form}
          initialValues={values}
          onValuesChange={onChange}
          onFinish={onFinish}
          loading={groupsReducer.loading.ASSIGN_MSACLASS_TEACHER?.state}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          onRedirectCancelButton={() => onClose()}
        >
          <div className="container-form">
            <Form.Item
              label="Profesor"
              name="teacher_id"
              rules={[{ required: true }]}
            >
              <TeacherSelect studyPlanId={ group?.study_plan_id } />
            </Form.Item>
            <Form.Item
              name='assign_as_main_teacher'
            >
              <Checkbox onChange={ handleMainGroupTeacherCheckbox }>Asignar como titular la clase</Checkbox>
            </Form.Item>
            <Form.Item
              label="A partir de"
              name="start_date"
              rules={[{ required: true }]}
            >
              <DatePicker
                className="date-picker"
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                dateRender={(date) => CellDatePicker(date, classesOrigin)}
              />
            </Form.Item>
            <Form.Item
              name='multiple-classes'
            >
              <Checkbox disabled={mainTeacher} onChange={ handleMultipleClassesCheckbox }>Asigar a múltiples clases</Checkbox>
            </Form.Item>
            <Form.Item
              label="Hasta"
              name="end_date"
              rules={[{ required: multipleClasses }]}
            >
              <DatePicker
                className="date-picker"
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                disabled={!multipleClasses}
                dateRender={(date) => CellDatePicker(date, classesOrigin)}
              />
            </Form.Item>
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default EditTeacherAssignationForm;
